<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn
        v-if="!noBtn"
        color="color3"
        text
        v-on="on"
      >Advanced Court Manager</v-btn>
    </template>
    <!-- MAIN CONTENT -->
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>{{tournament.name}} Courts 222</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog=false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid class="pt-0">
        <v-row dense class="justify-end">
          <court-grouper :tournament="tournament"></court-grouper>
          <add-courts :tournament="tournament"></add-courts>
        </v-row>
        <v-row dense v-if="dialog">
          <v-col cols="12">
            <div class="slide-wrapper">
              <v-sheet :width="`${200 * courtNames.length}px`"  height="75vh" color="red" class="slide-content">
                <v-calendar
                ref="calendar"
                color="primary"
                type="category"
                :categories="courtNames"
                style="width: 100%"
                :events="events"
                :value="calVal"
                :interval-minutes="iMinutes"
                :interval-count="iCount"
                :first-time="calStartTime"
                :event-color="getEventColor"
                :event-ripple="false"
                category-show-all
                @click:event="selectMatch"
                >
                  <template v-slot:event="{ event, timeSummary }">
                    <div class="pa-1 v-event-draggable">
                      <div>{{event.titleArray | pipeArray}}</div>
                      <div>{{event.name}}</div>
                      <div>{{timeSummary()}}</div>
                    </div>
                  </template>
                </v-calendar>
              </v-sheet>
            </div>
          </v-col>
        </v-row>
        <v-row dense> <!-- SELECTORS -->
          <!-- DAY SELECTOR -->
          <v-col cols="6">
            <v-select
              dense
              :items="days"
              v-model="day"
              label="Day"
              color="color3"
              item-color="color3"
              attach
              :solo-inverted="days.length > 1"
            ></v-select>
          </v-col>
          <!-- DIVISION SELECTOR -->
          <v-col cols="6">
            <v-select
              dense
              :items="divisions"
              v-model="division"
              label="Division"
              color="color3"
              item-color="color3"
              attach
            >
            </v-select>
          </v-col>
          <!-- GROUP SELECTOR -->
          <v-col cols="6" v-if="!!groups">
            <v-select
              dense
              :items="groups"
              v-model="selectedGroups"
              label="Court Groups"
              color="color3"
              item-color="color3"
              attach
              item-text="name"
              return-object
              multiple
              clearable
              :menu-props="{ closeOnContentClick: true }"
            >
            </v-select>
          </v-col>
          <!-- COURT SELECTOR -->
          <v-col cols="6">
            <v-select
              dense
              :items="courts"
              v-model="selectedCourts"
              label="Courts"
              color="color3"
              item-color="color3"
              attach
              multiple
              clearable
            >
            </v-select>
          </v-col>
          <v-col cols="6" v-if="user && user.id === 1">
            <v-text-field
              label="Tournament Id"
              v-model="addId"
              type="number"
              dense
            >
              <template v-slot:append-outer>
                <v-btn
                  color="success"
                  small
                  :disabled="!addId"
                  @click.stop="addTournament"
                >
                  add
                </v-btn>
                <v-btn
                  color="success"
                  small
                  @click.stop="test"
                >
                  test
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- MATCH DIALOG -->
    <v-dialog
      v-if="selectedMatch"
      v-model="matchDialog"
      scrollable
      :persistent="false"
      width="90vw"
      max-width="800px"
      transition="dialog-transition"
    >
      <full-match-card
        :key="`${selectedMatch.number}-${selectedMatch.id}`"
        style="width: 100%"
        :matchIn="selectedMatch"
        :pool="selectedPool"
        :bracket="selectedBracket"
        :bracketCard="selectedMatch.meta.parent.type === 'bracket'"
        :division="selectedDivision"
        :round="selectedDay"
        :jump="true"
        @match-saved="onMatchSaved"
      ></full-match-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import RouteMixin from '@/Mixins/RouteMixin.js'
import FullMatchCard from '@/components/Tournament/Match/FullMatchCard'
import moment from 'moment'
import flatten from '@/helpers/ArrayFlatten'
import * as actions from '@/store/ActionTypes'
const CourtGrouper = () => import('./CourtGrouper')
const AddCourts = () => import('./AddCourtsDialog')

export default {
  mixins: [RouteMixin],
  props: ['noBtn', 'open'],
  data () {
    return {
      dialog: false,
      hideCourts: [],
      day: null,
      division: null,
      matchDialog: false,
      selectedMatch: null,
      selectedPool: null,
      selectedBracket: null,
      calStartTime: '08:00',
      endTime: '20:00',
      selectedGroups: [],
      selectedCourts: [],
      iMinutes: 30,
      hScroll: 0,
      numOfCourts: 6,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      addId: null,
      eventMeta: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'color1IsDark', 'user']),
    iCount () {
      return (moment(this.endTime, 'HH:mm').diff(moment(this.calStartTime, 'HH:mm'), 'hours') * 60) / this.iMinutes
    },
    matches () {
      return this.tournament.matches
    },
    courts () {
      return this.tournament.courts
    },
    filteredCourts () {
      return this.selectedCourts.length > 0 ? this.selectedCourts : this.courts // this.courts.slice(this.hScroll, (this.numOfCourts + this.hScroll))
    },
    showScroll () {
      return this.courts && this.filteredCourts.length !== this.courts.length
    },
    courtNames () {
      return this.filteredCourts.map(m => m.toLowerCase().includes('court') ? m : `Court ${m}`)
    },
    events () {
      let base = this.eventMeta.filter(f => f.dayWithMo === this.day && this.filteredCourts.includes(f.court))
      if (this.division) base = base.filter(f => f.meta.division.id === this.division)
      return base
    },
    eventsOG () {
      let base = this.matches.filter(f => f.meta && f.dayWithMo === this.day && this.filteredCourts.includes(f.court))
      if (this.division) base = base.filter(f => f.meta.division.id === this.division)
      return base.map(m => {
        const c = m.court
        return {
          start: m.startTime.format('YYYY-MM-DD HH:mm'),
          end: m.endTime.format('YYYY-MM-DD HH:mm'),
          category: c.toLowerCase().includes('court') ? c : `Court ${c}`,
          name: m.meta.titleArray ? m.meta.titleArray.join(' | ') : m.meta.name,
          match: m,
          color: m.complete ? 'success' : 'error'
        }
      })
    },
    groups () {
      return this.tournament.jProps && this.tournament.jProps.courtGroups
    },
    days () {
      return [...new Set(this.matches.filter(f => f.dayWithMo).map(m => m.dayWithMo))]
    },
    calVal () {
      return this.day ? moment(this.day, 'MMM Do YYYY').format('YYYY-MM-DD') : null
    },
    divisions () {
      const d = this.tournament.publicDivisions.map(d => {
        return {
          text: d.name,
          value: d.id
        }
      })
      return [
        { text: 'All', value: null },
        ...d
      ]
    },
    selectedDivision () {
      return this.selectedMatch && this.tournament.divisions.find(f => f.id === this.selectedMatch.meta.division.id)
    },
    selectedDay () {
      return this.selectedDivision && this.selectedDivision.days.find(f => f.id === this.selectedMatch.meta.round.id)
    }
  },
  methods: {
    startDrag ({ event, timed, eventParsed }) {
      if (event && timed) {
        console.log(event)
        // console.log(eventParsed)
        event.start = new Date(event.start).getTime()
        event.end = new Date(event.end).getTime()
        this.dragEvent = event
        this.dragTime = null
      }
    },
    startTime (tms) {
      if (this.dragEvent && this.dragTime === null) {
        // console.log(tms)
        const mouse = this.toTime(tms)
        console.log(mouse)
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      }
    },
    mouseMove (tms) {
      if (this.dragEvent && this.dragTime !== null) {
        const mouse = this.toTime(tms)
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
        console.log(this.dragEvent)
      }
    },
    endDrag () {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime (time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor (event) {
      return event.color
    },
    selectDay () {
      if (this.$route.query.day) {
        const d = moment(this.$route.query.day)
        if (d.isValid) {
          const x = this.days.find(f => f === d.format('MMM Do YYYY'))
          if (x) {
            this.day = x
            this.$router.replace({ query: null })
            return
          }
        }
      }
      if (!this.day && this.days.length) {
        this.day = this.days[0]
      }
    },
    selectMatch ({ event }) {
      console.log('seleceted')
      this.selectedMatch = this.tournament.matches.find(f => f.id === event.meta.id)
      const m = event
      if (m.meta.parent.type === 'pool') {
        this.selectedPool = this.tournament.pools.find(f => f.id === m.meta.parent.id)
      } else {
        this.selectedBracket = this.tournament.brackets.find(f => f.id === m.meta.parent.id)
      }
      this.matchDialog = true
    },
    onMatchSaved () {
      this.matchDialog = false
      this.selectedMatch = null
    },
    applyGroupFilter () {
      if (this.selectedGroups.length === 0) {
        this.hideCourts = []
        return
      }

      const show = flatten(this.selectedGroups.map(m => m.courts))
      this.hideCourts = this.allCourts.filter(f => !show.includes(f))
    },
    addTournament () {
      this.$store.dispatch(actions.SUBSCRIBE_TO_TOURNAMENT, { tournamentId: this.addId, add: true })
    },
    test () {
      this.tournament.sortDivisions()
      console.log(this.matches)
    },
    initEvents () {
      this.loading = true
      console.log(new Date())
      const data = this.tournament.getMatchMeta()
      console.log(new Date())
      this.mapEvents(data)
      console.log(new Date())
      this.loading = false
    },
    mapEvents (data) {
      const a = data.filter(f => f.startTime)
      const b = data.filter(f => !f.startTime)
      console.log(b)
      this.eventMeta = a.map(m => {
        const c = m.court
        return {
          start: m.startTime.format('YYYY-MM-DD HH:mm'),
          end: m.endTime.format('YYYY-MM-DD HH:mm'),
          category: c.toLowerCase().includes('court') ? c : `Court ${c}`,
          name: m.titleArray ? m.titleArray.join(' | ') : m.name,
          color: m.status === 'Complete' ? 'success' : m.status === 'Started' ? 'warning' : 'error',
          court: c,
          dayWithMo: m.dayWithMo,
          meta: m
        }
      })
    }
  },
  components: {
    FullMatchCard,
    CourtGrouper,
    AddCourts
  },
  watch: {
    days: 'selectDay',
    selectedGroups: 'applyGroupFilter',
    open: function (v) {
      if (v) {
        this.dialog = v
      }
    },
    dialog: function (v) {
      this.$emit('dialog-change', v)
      if (v) this.initEvents()
    }
  },
  mounted () {
    this.selectDay()
    // this.initEvents()
  },
  created () {
    // this.tournament.updateMatchMeta()
  }
}
</script>

<style scoped>
>>> .v-calendar-daily_head-day-label, >>> .v-calendar-daily_head-weekday {
  display: none;
}
.slide-wrapper {
    contain: content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: auto;
}
.slide-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: relative;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    white-space: nowrap;
}
</style>
